<template>
  <div>
    <div v-if="!loading">
      <ReportTable
        :fields="selectReportFields"
        :items="selectReportData"
        :isGrouped="selectIsGrouped"
      />
    </div>

    <div v-else>
      <div class="card">
        <b-skeleton-table :columns="4" :rows="10" class="text-center" />
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from './ReportTypes/ReportTable'
import { createNamespacedHelpers } from 'vuex'

const ReportBuilderModule = createNamespacedHelpers('reportBuilder')

export default {
  name: 'ReportSection',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ReportTable,
  },
  computed: {
    ...ReportBuilderModule.mapGetters([
      'selectMetrics',
      'selectReportData',
      'selectReportFields',
      'selectIsGrouped',
    ]),
  },
}
</script>
