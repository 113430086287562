<template>
  <div>
    <b-sidebar id="options-sidebar" right shadow width="500px" no-close-on-route-change>
      <template #header>
        <div class="d-flex justify-content-between w-100">
          <div
            class="ml-2 mt-3 flip d-flex align-items-center justify-content-center"
            style="height: 40px; width: 40px; border-radius: 50%"
            v-b-toggle.options-sidebar
          >
            <i class="fas fa-chevron-left"></i>
          </div>

          <div class="mr-4 mt-3 d-flex align-items-center justify-content-center">
            <button
              class="btn btn-primary btn-sm"
              style="width: 100px"
              v-b-toggle.options-sidebar
              @click="updateMetrics"
            >
              Apply
            </button>
          </div>
        </div>
      </template>

      <div class="px-5 mt-4">
        <h2 class="mb-4">Select Data</h2>
        <Draggable v-model="metrics" v-bind="dragOptions">
          <transition-group type="transition">
            <div
              class="card mb-3 px-4 py-2 grabbable"
              v-for="metric in sortedMetrics"
              :key="metric.key"
            >
              <div class="d-flex">
                <div class="d-flex align-items-center text-muted pr-4">
                  <i class="fa fa-align-justify"></i>
                </div>

                <span class="mr-auto" style="font-size: 13px">
                  <div>{{ metric.label }}</div>
                  <div class="text-muted">{{ metric.description }}</div>
                </span>

                <div class="d-flex align-items-center">
                  <input type="checkbox" v-model="metric.active" />
                </div>
              </div>
            </div>
          </transition-group>
        </Draggable>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { createNamespacedHelpers } from 'vuex'

const ReportBuilderModule = createNamespacedHelpers('reportBuilder')

export default {
  name: 'OptionsDrawer',
  components: {
    Draggable,
  },
  data: () => ({
    metrics: [],
  }),
  mounted() {
    this.metrics = this.selectMetrics.map((m) => ({ ...m }))
  },
  computed: {
    ...ReportBuilderModule.mapGetters(['selectMetrics']),
    dragOptions() {
      return {
        animation: 300,
        ghostClass: 'ghost',
      }
    },
    sortedMetrics() {
      return [...this.metrics].sort((a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1))
    },
  },
  watch: {
    selectMetrics() {
      this.metrics = this.selectMetrics.map((m) => ({ ...m }))
    },
  },
  methods: {
    ...ReportBuilderModule.mapActions(['setMetrics', 'setSelectionsUpdated']),
    updateMetrics() {
      this.setMetrics(this.metrics)
      this.setSelectionsUpdated(true)
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          metrics: this.metrics.reduce((a, c) => {
            if (c.active) a.push(c.key)
            return a
          }, []),
        },
      })
      this.$emit('metricsUpdated')
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .bg-light {
  background-color: #f9fbfd !important;
}

.flip {
  transition: 0.3s ease-out;
}

.flip:hover {
  transform: rotateZ(180deg);
}

.grabbable {
  cursor: grab;
}

.ghost {
  opacity: 0.5;
  background: #edf2f9;
}
</style>
