<template>
  <div id="groupby-filter-select">
    <treeselect
      v-model="selectedOption"
      :multiple="false"
      :options="options"
      :placeholder="placeholder || 'Select Grouping'"
      :disable-branch-nodes="true"
      value-format="object"
      :clear-on-select="true"
      @select="emitSelectedOption"
      :beforeClearAll="clearAll"
    >
      <div slot="value-label" slot-scope="{ node }">{{ node.label }}</div>
    </treeselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { createNamespacedHelpers } from 'vuex'

const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'GroupByFilter',
  props: {
    init: { type: Object },
    placeholder: { type: String },
  },
  components: {
    Multiselect,
    Treeselect,
  },
  computed: {
    ...GroupModule.mapGetters(['selectGroups']),
  },
  data: () => ({
    selectedOption: null,
    options: [],
  }),
  mounted() {
    this.initFilterOptions()
  },
  methods: {
    clearAll() {
      this.selectedOption = null
      this.emitSelectedOption()
    },
    emitSelectedOption(option) {
      this.$emit('update', option || this.selectedOption)
    },
    initFilterOptions() {
      this.options = this.selectGroups.reduce((acc, curr) => {
        if (curr.levelNames?.length && curr.children?.length) {
          acc.push({
            id: curr._id,
            label: curr.name,
            children: curr.levelNames.reduce((a, c) => {
              a.push({ id: `${curr._id}-${c}`, label: c })
              return a
            }, []),
          })
        }
        return acc
      }, [])
      if (this.init) {
        this.selectedOption = {
          // id is a combo of the parent's id and the level name (only unique option)
          id: this.init.groupId + this.init.levelName,
          label: this.init.levelName,
        }
      }
    },
  },
}
</script>

<style lang="scss">
#groupby-filter-select {
  .vue-treeselect__control {
    height: 40.5px;
    border-color: #d2ddec;
    width: unset;
  }

  .vue-treeselect__placeholder {
    margin-top: 2px;
  }

  .vue-treeselect__option,
  .vue-treeselect__option-arrow,
  .vue-treeselect__placeholder {
    color: #6e84a3;
  }

  .vue-treeselect__control-arrow {
    display: none;
    width: 0;
  }
}
</style>
