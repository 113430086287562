<template>
  <MainContent>
    <div class="container-fluid" :key="activeCompany._id" v-if="activeCompany">
      <div class="header mb-2">
        <div class="header-body">
          <div class="row align-items-center">
            <!-- title -->
            <div class="col">
              <h1 class="header-title">Report Builder</h1>
            </div>
            <div class="col-auto" v-if="activeCompany.reportBuilderAccess">
              <button class="btn btn-outline-primary mr-2" @click="verifyExport()">
                <span class="fe fe-download" v-if="!pendingExport"></span>
                <span class="fas fa-spinner fa-spin" v-else></span>
                Export
              </button>
              <button class="btn btn-outline-primary action-btn" v-b-toggle.options-sidebar>
                Select Metrics
              </button>
            </div>
          </div>
        </div>
      </div>
      <ReportBuilderView v-if="activeCompany.reportBuilderAccess" />
      <ReportBuilderOffState v-else />
    </div>
  </MainContent>
</template>

<script>
import { trackEvent } from '@/lib/analytics'
import ReportBuilderView from '../components/Modules/ReportBuilder/ReportBuilderView'
import ReportBuilderOffState from '../components/Modules/ReportBuilder/ReportBuilderOffState'
import MainContent from '../components/MainContent/MainContent'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')
const ReportBuilderModule = createNamespacedHelpers('reportBuilder')

export default {
  name: 'ReportBuilderPage',
  components: {
    MainContent,
    ReportBuilderView,
    ReportBuilderOffState,
  },
  data: () => ({
    pendingExport: false,
  }),
  async mounted() {
    trackEvent(this.$intercom, 'ReportBuilder')
  },
  computed: {
    ...CompanyModule.mapState(['activeCompany']),
  },
  methods: {
    ...ReportBuilderModule.mapActions(['generateReportCSV']),
    async verifyExport() {
      const message = `
        A CSV file will be created with the filters 
        that are currently applied to the report table.
        `
      const h = this.$createElement
      const titleVNode = h('div', [
        h('h2', { class: ['mb-0'], domProps: { innerHTML: 'Confirm Export' } }),
      ])
      const exportConfirmed = await this.$bvModal.msgBoxConfirm(message, {
        title: [titleVNode],
        size: 'md',
        okTitle: 'Export',
        cancelTitle: 'Cancel',
        centered: true,
      })
      if (exportConfirmed) this.exportReportCSV()
    },

    async exportReportCSV() {
      try {
        this.$notify({
          duration: 1500,
          title: `Export Started`,
          text: `We'll notify you when it is ready for download.`,
        })

        this.pendingExport = true
        const response = await this.generateReportCSV()
        this.pendingExport = false

        let fileURL = window.URL.createObjectURL(new Blob([response]))
        let fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', `Report.csv`)
        document.body.appendChild(fileLink)
        fileLink.click()

        this.$notify({
          duration: 3000,
          text: `Successfully Downloaded Export`,
        })
      } catch (error) {
        this.pendingExport = false
        console.log(error)
        this.$notify({
          type: 'error',
          duration: 5000,
          title: `Exporting report failed!`,
          text: `Please refresh the page and try again.`,
        })
      }
    },
  },
}
</script>

<style scoped>
button:focus {
  box-shadow: none;
}
.action-btn {
  height: 42px;
}
</style>
