<template>
  <div>
    <div v-if="initialized">
      <!-- filters -->
      <ReportBuilderFilters
        class="mb-4"
        @filtersApplied="refreshReports"
        @preloaded="loading = false"
      />

      <!-- reports -->
      <ReportSection :loading="loading" />
    </div>

    <!-- loader -->
    <div v-else>
      <FullLoader />
    </div>
    <OptionsDrawer @metricsUpdated="refreshReports" />
  </div>
</template>

<script>
import ReportBuilderFilters from './ReportBuilderFilters'
import ReportSection from './ReportSection'
import OptionsDrawer from './OptionsDrawer'
import FullLoader from '@/components/MainContent/FullLoader'
import { createNamespacedHelpers } from 'vuex'

const ReportBuilderModule = createNamespacedHelpers('reportBuilder')
const LocationModule = createNamespacedHelpers('location')
const DataCoreModule = createNamespacedHelpers('dataCore')
const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'ReportBuilderView',
  components: {
    ReportBuilderFilters,
    ReportSection,
    FullLoader,
    OptionsDrawer,
  },
  data: () => ({
    loading: true,
    initialized: false,
    activeCustomReportFilters: null,
  }),
  async mounted() {
    this.initialized = false
    await this.initialize()
    this.initialized = true
  },
  computed: {
    ...DataCoreModule.mapGetters(['selectActiveCompanyIds']),
  },
  watch: {
    selectActiveCompanyIds: {
      deep: true,
      immediate: true,
      handler(val) {
        this.initialize(true)
      },
    },
  },
  methods: {
    ...ReportBuilderModule.mapActions(['fetchReportData', 'fetchCustomReports']),
    ...LocationModule.mapActions(['getUserLocationsByActiveCompany']),
    ...GroupModule.mapActions(['fetchGroups']),
    async refreshReports() {
      this.loading = true
      await this.fetchReportData()
      this.loading = false
    },
    async initialize(refresh) {
      this.loading = true
      await Promise.all([
        this.getUserLocationsByActiveCompany(),
        this.fetchGroups({
          memberType: 'location',
          companyIds: this.selectActiveCompanyIds,
        }),
        this.fetchCustomReports(),
      ])

      if (refresh) {
        await this.refreshReports()
      }
      this.loading = false
    },
  },
}
</script>
