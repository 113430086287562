<template>
  <div class="card col-12 mt-6">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/rocket.png"
            alt="..."
            class="img-fluid mt--6 mb-4"
            style="max-width: 150px"
          />

          <h2>Report Builder</h2>

          <p class="text-muted mb-4">Gain access to greater reporting and location visibility.</p>
          <a
            href="mailto:upgrade@ovationup.com?subject=Upgrade%20Request&body=I%20would%20like%20access%20to%20report%20builder."
            class="btn btn-lg btn-primary"
          >
            Request Access
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportBuilderOffState',
}
</script>

<style scoped lang="scss"></style>
