<template>
  <div class="container pl-0 ml-0">
    <div class="row justify-content-start" v-if="initialized">
      <!-- date -->
      <div class="dropdown col-auto">
        <h6 class="header-pretitle mt-3">Date Range</h6>
        <DatePicker
          @update="dateUpdate"
          :minDate="activeCompany.created"
          :dateRange="filteredDateRange"
        />
      </div>

      <!-- location -->
      <div class="dropdown col-auto" v-if="selectActiveLocations.length > 1">
        <h6 class="header-pretitle mt-3">Locations</h6>
        <GroupingFilter
          :nonGroupMembers="selectActiveLocations.filter((l) => !l.hideFromReports)"
          :disabledNonGroupMembers="selectDisabledLocations"
          nonGroupMemberLabel="Locations"
          memberType="location"
          @update="groupingUpdate"
          :init="{
            nonGroupMemberIds: this.filteredLocations,
            groupIds: this.filteredGroups,
            disabledNonGroupMemberIds: this.filteredDisabledLocations,
          }"
        />
      </div>

      <!-- group by -->
      <div
        class="dropdown col-auto"
        v-if="
          selectGroups.length && selectActiveLocations.length > 1 && onlineUser.role === 'admin'
        "
      >
        <h6 class="header-pretitle mt-3">Group By</h6>
        <GroupByFilter @update="groupByUpdate" :init="filteredGroupBy" />
      </div>

      <div class="col-auto d-flex align-items-end">
        <div v-if="showSaveBtn && !processing && onlineUser.role === 'admin'">
          <button class="btn btn-link p-0" @click="setDefault">Set as default</button>
        </div>
        <div v-if="processing" class="loader loader-sm loader-primary"></div>
      </div>
    </div>
  </div>
</template>

<script>
import GroupingFilter from '@/components/Modules/Filters/GroupingFilter'
import DatePicker from '@/components/Modules/Filters/DatePicker'
import GroupByFilter from '@/components/Modules/Filters/GroupByFilter'

import { createNamespacedHelpers } from 'vuex'

const ReportBuilderModule = createNamespacedHelpers('reportBuilder')
const CompanyModule = createNamespacedHelpers('company')
const LocationModule = createNamespacedHelpers('location')
const GroupModule = createNamespacedHelpers('group')
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'ReportBuilderFilters',
  components: {
    GroupingFilter,
    DatePicker,
    GroupByFilter,
  },
  data: () => ({
    initialized: false,
    processing: false,
    filtersSet: false,
    firstPass: true,
    filteredDateRange: null,
    filteredGroups: null,
    filteredGroupMembers: null,
    filteredLocations: null,
    filteredDisabledLocations: null,
    filteredGroupBy: null,
  }),
  mounted() {
    this.initializeData()
  },
  computed: {
    ...LocationModule.mapGetters([
      'selectActiveLocations',
      'selectDisabledLocations',
      'selectIsActive',
    ]),
    ...CompanyModule.mapGetters(['activeCompany']),
    ...ReportBuilderModule.mapGetters(['selectActiveCustomReport', 'showSaveBtn']),
    ...GroupModule.mapGetters(['selectGroups']),
    ...UserModule.mapGetters(['onlineUser']),
  },
  methods: {
    ...ReportBuilderModule.mapActions([
      'applyFilters',
      'markMetricsActive',
      'saveCustomReportChanges',
      'setSelectionsUpdated',
    ]),
    ...GroupModule.mapActions(['selectGroupLocationIds']),
    initializeData() {
      const query = this.$route.query
      const CR_Filters = this.selectActiveCustomReport.filters
      const CR_Metrics = this.selectActiveCustomReport.metrics
      const initByQuery = Object.keys(query).length

      this.markMetricsActive(query.metrics || CR_Metrics)
      this.filteredDateRange = query.dateRange
      this.filteredLocations = initByQuery
        ? query.locs
        : CR_Filters?.locations.filter((loc) => this.selectIsActive(loc))
      this.filteredDisabledLocations = initByQuery
        ? query.disLocs
        : CR_Filters?.locations.filter((loc) => !this.selectIsActive(loc))
      this.filteredGroups = initByQuery ? query.groups : CR_Filters?.groups
      this.filteredGroupBy = initByQuery
        ? query.groupId && query.groupByLevel
          ? { groupId: query.groupId, levelName: query.groupByLevel }
          : null
        : CR_Filters?.groupBy?.groupId && CR_Filters?.groupBy?.levelName
        ? CR_Filters.groupBy
        : null

      if (this.filteredGroups && !Array.isArray(this.filteredGroups)) {
        this.filteredGroups = [this.filteredGroups]
      }
      if (this.filteredLocations && !Array.isArray(this.filteredLocations)) {
        this.filteredLocations = [this.filteredLocations]
      }
      if (this.filteredDisabledLocations && !Array.isArray(this.filteredDisabledLocations)) {
        this.filteredDisabledLocations = [this.filteredDisabledLocations]
      }
      this.initialized = true
    },
    groupingUpdate(val) {
      this.filteredGroups = val && val.groupIds && val.groupIds.length ? val.groupIds : null
      this.filteredGroupMembers =
        val && val.memberIds && val.memberIds.length ? val.memberIds : null
      this.filteredLocations =
        val && val.nonGroupMemberIds && val.nonGroupMemberIds.length ? val.nonGroupMemberIds : null
      this.filteredDisabledLocations =
        val && val.disabledNonGroupMemberIds && val.disabledNonGroupMemberIds.length
          ? val.disabledNonGroupMemberIds
          : null
      this.formatAndApplyFilters()
    },
    groupByUpdate(val) {
      this.filteredGroupBy = val ? { groupId: val.id, levelName: val.label } : null
      this.formatAndApplyFilters()
    },
    dateUpdate(val) {
      this.filteredDateRange = val
      this.formatAndApplyFilters()
    },
    async formatAndApplyFilters() {
      let locationsToFilter = [
        ...new Set([
          ...(this.filteredLocations || []),
          ...(this.filteredDisabledLocations || []),
          ...(this.filteredGroupMembers || []),
        ]),
      ]
      const filtersChanged = await this.applyFilters({
        locations: locationsToFilter.length
          ? locationsToFilter
          : this.filteredGroups?.length
          ? await this.selectGroupLocationIds(this.filteredGroups)
          : null,
        dateRange:
          this.filteredDateRange && Object.keys(this.filteredDateRange).length
            ? this.filteredDateRange
            : null,
        groupBy: this.filteredGroupBy,
        groups: this.filteredGroups,
      })

      this.$router
        .replace({
          path: this.$route.path,
          query: {
            dateRange: this.filteredDateRange,
            ...(this.filteredGroups && this.filteredGroups.length
              ? { groups: this.filteredGroups }
              : {
                  ...(this.filteredLocations?.length ? { locs: this.filteredLocations } : {}),
                  ...(this.filteredDisabledLocations?.length
                    ? { disLocs: this.filteredDisabledLocations }
                    : {}),
                }),
            groupId: this.filteredGroupBy?.groupId,
            groupLevel: this.filteredGroupBy?.levelName,
            metrics: this.$route.query.metrics,
          },
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') throw err
        })
      if (!filtersChanged) {
        this.$emit('preloaded')
        return
      }
      this.firstPass ? (this.firstPass = false) : this.setSelectionsUpdated(true)
      this.$emit('filtersApplied')
    },
    async setDefault() {
      this.processing = true
      try {
        await this.saveCustomReportChanges()
        this.$notify({ text: 'Successfully saved changes' })
      } catch (error) {
        this.$notify({ type: 'error', text: 'Failed to save changes' })
      }
      this.processing = false
    },
  },
}
</script>
