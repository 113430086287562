<template>
  <div class="card">
    <b-table
      :fields="fields"
      :items="items"
      responsive
      hover
      class="mb-0"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :table-class="['card-table', 'table-nowrap']"
      small
    >
      <template #head()="{ label, field: { key, sortable, tooltip } }">
        <span v-b-tooltip.hover :title="tooltip">
          {{ label }}
        </span>
        <template v-if="sortable">
          <template v-if="sortBy !== key">
            <i class="fe fe-arrow-down"></i>
            <i class="fe fe-arrow-up"></i>
          </template>
          <i v-else-if="sortDesc" class="fe fe-arrow-down" style="font-weight: bold"></i>
          <i v-else class="fe fe-arrow-up" style="font-weight: bold"></i>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'ReportTable',
  props: {
    fields: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    isGrouped: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    sortBy: '',
    sortDesc: true,
  }),
}
</script>

<style lang="scss" scoped>
::v-deep tr > [aria-sort] {
  background-image: none !important;
}
</style>
