import { render, staticRenderFns } from "./ReportTable.vue?vue&type=template&id=1e21ca55&scoped=true"
import script from "./ReportTable.vue?vue&type=script&lang=js"
export * from "./ReportTable.vue?vue&type=script&lang=js"
import style0 from "./ReportTable.vue?vue&type=style&index=0&id=1e21ca55&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e21ca55",
  null
  
)

export default component.exports