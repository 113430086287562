import { render, staticRenderFns } from "./ReportBuilderPage.vue?vue&type=template&id=31df1509&scoped=true"
import script from "./ReportBuilderPage.vue?vue&type=script&lang=js"
export * from "./ReportBuilderPage.vue?vue&type=script&lang=js"
import style0 from "./ReportBuilderPage.vue?vue&type=style&index=0&id=31df1509&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31df1509",
  null
  
)

export default component.exports