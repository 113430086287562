var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pl-0 ml-0"},[(_vm.initialized)?_c('div',{staticClass:"row justify-content-start"},[_c('div',{staticClass:"dropdown col-auto"},[_c('h6',{staticClass:"header-pretitle mt-3"},[_vm._v("Date Range")]),_c('DatePicker',{attrs:{"minDate":_vm.activeCompany.created,"dateRange":_vm.filteredDateRange},on:{"update":_vm.dateUpdate}})],1),(_vm.selectActiveLocations.length > 1)?_c('div',{staticClass:"dropdown col-auto"},[_c('h6',{staticClass:"header-pretitle mt-3"},[_vm._v("Locations")]),_c('GroupingFilter',{attrs:{"nonGroupMembers":_vm.selectActiveLocations.filter((l) => !l.hideFromReports),"disabledNonGroupMembers":_vm.selectDisabledLocations,"nonGroupMemberLabel":"Locations","memberType":"location","init":{
          nonGroupMemberIds: this.filteredLocations,
          groupIds: this.filteredGroups,
          disabledNonGroupMemberIds: this.filteredDisabledLocations,
        }},on:{"update":_vm.groupingUpdate}})],1):_vm._e(),(
        _vm.selectGroups.length && _vm.selectActiveLocations.length > 1 && _vm.onlineUser.role === 'admin'
      )?_c('div',{staticClass:"dropdown col-auto"},[_c('h6',{staticClass:"header-pretitle mt-3"},[_vm._v("Group By")]),_c('GroupByFilter',{attrs:{"init":_vm.filteredGroupBy},on:{"update":_vm.groupByUpdate}})],1):_vm._e(),_c('div',{staticClass:"col-auto d-flex align-items-end"},[(_vm.showSaveBtn && !_vm.processing && _vm.onlineUser.role === 'admin')?_c('div',[_c('button',{staticClass:"btn btn-link p-0",on:{"click":_vm.setDefault}},[_vm._v("Set as default")])]):_vm._e(),(_vm.processing)?_c('div',{staticClass:"loader loader-sm loader-primary"}):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }